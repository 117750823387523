import React from 'react'

export const WelcomeBox = ({ props }: any) => {
  return (
    <div className="md:w-[816px] md: mt-[40px] px-[32px] py-[16px] flex flex-col md:flex-row items-center justify-between border-[2px] border-href rounded-[20px]">
      <div className="flex flex-col ">
        <p className="text-href font-bold text-[24px] text-left">
          지금 회원가입 하면
        </p>
        <p className="font-bold text-[32px] text-left">
          프리미엄 플랜 기능
          <br /> 무료체험 가능!
        </p>
      </div>
      <div className="text-[18px] font-bold flex flex-col gap-[16px] mt-[40px] md:mt-0">
        <p className="flex items-center gap-[4px]">
          {' '}
          <img
            src="assets/img/check_circle_blue.png"
            alt="check"
            width={24}
            height={24}
          />
          최고사양 플랜의 모든 기능을 써보고 결정하세요!
        </p>
        <p className="flex items-center gap-[4px]">
          {' '}
          <img
            src="assets/img/check_circle_blue.png"
            alt="check"
            width={24}
            height={24}
          />
          영상 파일 다운로드는 물론, 유튜브 자동 업로드까지!
        </p>
      </div>
    </div>
  )
}

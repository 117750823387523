import React, { useEffect } from 'react'
import './App.css'
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Home from './pages/Home'
import SignUp from './pages/SignUp'
import Header from './components/layout/Header'
import { Footer } from './components/layout/Footer'
import { Layout } from './components/layout/Layout'
import { SignIn } from './pages/SignIn'
import useUserStore from './store/useUserStore'
import { Youtube } from './pages/Youtube'
import { Video } from './pages/Video'
import { Terms } from './pages/Terms'
import { PriceGuide } from './pages/PriceGuide'
import { MyPayments } from './pages/MyPayments'
import { MyVideos } from './pages/MyVideos'
import Authentication from './components/Authentication/Authentication'
import { QnA } from './pages/QnA'
import { PaymentFail } from './pages/PaymentFail'
import { MyPoint } from './pages/MyPoint'
import { Test } from './pages/Test'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MyCashDashboard } from './pages/MyCashDashboard'
import { ShortVideo } from './pages/ShortVideo'

const queryClient = new QueryClient()
const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <ScrollToTop />
      <Header />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/video-editor" element={<Video />} />
          <Route path="/short-video-editor" element={<ShortVideo />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/my-payments" element={<MyPayments />} />
          <Route path="/youtube-auth" element={<Youtube />} />
          <Route path="/QnA" element={<QnA />} />
          {/* <Route
            key={'youtube-auth'}
            path="/youtube-auth"
            element={
              <Authentication
                userInfo={
                  userInfo &&
                  (userInfo?.userType === 'proUser' ||
                    userInfo?.userType === 'ultraUser')
                    ? true
                    : false
                }
              >
                <Youtube />
              </Authentication>
            }
          /> */}
          {/* <Route
            path="/my-payments"
            element={
              <Authentication userInfo={userInfo}>
                <MyPayments />
              </Authentication>
            }
          /> */}
          {/* <Route path="/my-point" element={<MyPoint />} /> */}
          <Route path="/my-videos" element={<MyVideos />} />
          <Route path="/payment-fail" element={<PaymentFail />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/price-guide" element={<PriceGuide />} />
          <Route path="/my-cash-dashboard" element={<MyCashDashboard />} />
          <Route path="/*" element={<Home />} />
        </Routes>
      </Layout>
      <Footer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App

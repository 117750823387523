import { useQuery } from '@tanstack/react-query'
import {
  createInitialMyPointData,
  getMyPoint,
  getPointList,
} from '../../../service/point'

export function useGetMyPoint() {
  return useQuery({
    queryKey: ['get-my-point'],
    queryFn: () => getMyPoint(),
    staleTime: 6 * 1000,
    initialData: createInitialMyPointData(),
  })
}

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'
import { PromptDuration } from '../../service/list'
interface Props {
  durationTooltip: boolean
  setDurationTooltip: Dispatch<SetStateAction<boolean>>
  durationList: PromptDuration[] | undefined
}

const DurationToolitip = ({
  children,
  durationTooltip,
  setDurationTooltip,
  durationList,
}: PropsWithChildren<Props>) => {
  const duration = durationList?.find((el) => el.cashRate === 1)
  return (
    <>
      {' '}
      <img
        src="assets/img/icon_tooltip.png"
        alt="툴팁"
        className=" w-[20px] h-[20px]"
        onMouseEnter={() => {
          setDurationTooltip(true)
        }}
      />
      {durationTooltip && (
        <div className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[200px] md:w-[366px] h-auto absolute border-[3px] border-main  rounded-[20px] bottom-[20px] left-20">
          <p className="font-bold md:text-[24px] text-base flex justify-between items-center">
            숏폼 길이란?
            <button
              onClick={() => {
                setDurationTooltip(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className=" md:text-base text-[10px]">
            숏폼은 1분 미만의 영상 형식을 말하는데요, 20~30초 정도의 짧고 가벼운
            영상으로 간략한 컨텐츠를 만들 수도 있고, 35~55초 정도의 영상으로
            조금 더 긴 이야기를 전달할 수 있어요.
          </p>
          <p className="flex flex-col">
            <p className="text-[14px] text-error">할인 이벤트중</p>
            <div className="flex gap-2 items-center">
              <img
                src="assets/img/cash.png"
                alt="point"
                width={11}
                height={13}
              />
              <s className="text-[#5E6068]  ml-2">{`${duration?.price}`}캐시</s>
              <span className="text-main ml-2">
                {' '}
                {`${duration?.originPrice}`}캐시
              </span>
            </div>
          </p>
        </div>
      )}
    </>
  )
}

export default DurationToolitip

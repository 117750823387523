import React from 'react'
import { My } from '../components/My'
import { FAQ } from '../components/FAQ'

export const QnA = () => {
  return (
    <div className="md:mt-[60px] md:max-w-[816px] max-w-[375px] mt-[24px] my-0 mx-auto flex flex-col items-center md:gap-[20px] gap-[40px]">
      <div className="md:w-full w-[343px] ">
        <My>
          <p>자주 묻는 질문</p>
        </My>
        <div className="flex justify-center my-[24px]">
          <p className="font-bold text-[24px] md:text-[48px]">자주 묻는 질문</p>
        </div>
        <FAQ />
      </div>
    </div>
  )
}

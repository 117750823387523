import React from 'react'
import { useNavigate } from 'react-router-dom'

export const ErrorBox = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-[#FFD8D8] text-error w-full h-[70px] rounded-[40px] flex md:flex-row flex-col items-center px-[40px] py-[32px] mt-[40px] text-[12px] md:text-base justify-center ">
      <p className="font-bold w-full">
        <button
          className="w-full text-left"
          onClick={() => {
            navigate('/youtube-auth')
          }}
        >
          {`필수! 자동업로드 설정 하러가기 >>`}
        </button>
      </p>
    </div>
  )
}

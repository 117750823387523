import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
interface Props {
  titleTooltip: boolean
  setTitleTooltip: Dispatch<SetStateAction<boolean>>
}

const ShortVideoTitleTooltip = ({
  children,
  titleTooltip,
  setTitleTooltip,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      <img
        src="assets/img/icon_tooltip.png"
        alt="툴팁"
        className=" w-[24px] h-[24px] z-10"
        onMouseEnter={() => {
          setTitleTooltip(true)
        }}
      />
      {titleTooltip && (
        <div className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[300px] md:w-[366px] h-auto absolute border-[3px] border-main  rounded-[20px] -bottom-40 right-10 md:right-40 z-50">
          <p className="font-bold md:text-[24px] text-base flex justify-between md:items-center">
            제목은 어떻게 사용되나요?
            <button
              onClick={() => {
                setTitleTooltip(false)
              }}
              className="flex flex-col h-[100%]"
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className=" md:leading-[19.2px] md:text-base  leading-[19.2px] text-[10px]">
            아래 숏폼 화면 예시와 같이{' '}
            <span className="font-bold text-main">
              영상의 상단에 입력하신 제목이 들어가며
            </span>
            , 50자 이하로 입력하시면 3줄 내외로 표기됩니다.
          </p>
          <div className="flex justify-center">
            <img
              src="assets/img/img_short_title_example.png"
              alt="example"
              width={240}
              height={424}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ShortVideoTitleTooltip

import API from './api'

export type CashType = {
  id: number
  amount: number
  popular: boolean
}
export type Cash = { total: CashType[]; popular: CashType[] }

export type RequestPurchasePoint = {
  id?: number
  orderId: string
  userId?: number
  productName?: string
  amount?: number
  price?: number
  vat?: number
  startDate?: string
  endDate?: string
  promotionCode?: string
  chargeOptionId?: number
}

export type MyPointType = {
  totalPoint: number
  purchasePoint: number
  activityPoint: number
}

export type MyPointTotalType = {
  totalPoint: {
    totalPoint: number
    purchasePoint: number
    activityPoint: number
  }
  saveTotalPoint: number
  useTotalPoint: number
  expireTotalPoint: number
  expectedExpireTotalPoint: number
}
export type SavePointType = {
  reasonType: string
  reasonTypeName: string
  amount: string
  createAt: string
  expireAt: string
}

export type ExceptSavePointType = {
  type: string
  typeName: string
  amount: string
  createAt: string
}

export interface MyPointHistory {
  savePointListTotalCount: string
  savePointList: SavePointType[]
}

export interface SpendingHistory {
  exceptSavePointListTotalCount: string
  exceptSavePointList: ExceptSavePointType[]
}

/**
 * 구매가능 포인트 리스트 요청
 * @returns
 */

export async function getPointList() {
  try {
    const data: Cash = await API.get(`/payment-point/amounts`, {})
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

/**
 * 결제요청 전  저장된 RequestPurchasePoint를 받는 함수
 * 이 entity에 값을 update해서 point 결제정보를 저장하는 함수
 *
 * @async
 * @function getPointOrderId
 * @returns {Promise<RequestPurchasePoint>}
 */
export async function getPointOrderId() {
  try {
    const data: RequestPurchasePoint = await API.get(
      `/payment-point/orderId`,
      {}
    )
    return data ?? {}
  } catch (error) {
    // 오류 처리
    throw error
  }
}

/**
 * 전체 포인트 / 구매포인트 / 보너스 포인트를 확인하는 함수
 *
 * @async
 * @function getMyPoint
 * @returns {Promise<MyPointType>}
 */

// 기본값 생성 함수
export function createInitialMyPointData(): MyPointType {
  return {
    totalPoint: 0,
    purchasePoint: 0,
    activityPoint: 0,
  }
}
export async function getMyPoint(): Promise<MyPointType> {
  try {
    const data: MyPointType = await API.get(`/point`, {})
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getMyPointTotal(): Promise<MyPointTotalType> {
  try {
    const data: MyPointTotalType = await API.get(`/point/total`, {})
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

/**
 * 포인트 구매 기록을 가져오는 함수
 *
 * @async
 * @function getMyPointHistory
 * @returns {Promise<MyPointType>}
 */

// 기본값 생성 함수
export function createInitialMyPointHistoryData(): MyPointHistory {
  return {
    savePointListTotalCount: '0',
    savePointList: [
      {
        reasonType: '',
        reasonTypeName: '',
        amount: '',
        createAt: '',
        expireAt: '',
      },
    ],
  }
}
export async function getMyPointHistory(
  page: number,
  pageSize: number
): Promise<MyPointHistory> {
  try {
    const data: MyPointHistory = await API.get(
      `/point/save?page=${page}&perPage=${pageSize}`,
      {}
    )
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

/**
 * 포인트 사용 기록을 가져오는 함수
 *
 * @async
 * @function getMySpendingHistory
 * @returns {Promise<ExceptSavePointType>}
 */

// 기본값 생성 함수
export function createInitialSpendingHistoryData(): SpendingHistory {
  return {
    exceptSavePointListTotalCount: '0',
    exceptSavePointList: [
      {
        type: '',
        typeName: '',
        amount: '',
        createAt: '',
      },
    ],
  }
}
export async function getMySpendingHistory(
  page: number,
  pageSize: number
): Promise<SpendingHistory> {
  try {
    const data: SpendingHistory = await API.get(
      `/point/except-save?page=${page}&perPage=${pageSize}`,
      {}
    )

    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function updateRequestPurchasePoint(
  entityData: RequestPurchasePoint
) {
  try {
    const data: RequestPurchasePoint = await API.put(
      `/payment-point/requestPurchasePoint`,
      entityData
    )
    return data ?? {}
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function confirmPoint({ paymentKey, orderId, amount }: any) {
  try {
    const data = await API.post('/payment-point/confirm', {
      paymentKey,
      orderId,
      amount,
    })
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}
export async function getPointHistory({
  page,
  pageSize,
}: {
  page: number
  pageSize: number
}) {
  try {
    const data = await API.get(`/point/history`, {
      params: {
        page,
        pageSize,
      },
    })
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getSpendingHistory({
  page,
  pageSize,
}: {
  page: number
  pageSize: number
}) {
  try {
    const data = await API.get('/point/spend', {
      params: {
        page,
        pageSize,
      },
    })
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export type PromotionInfo = {
  point: number
  pointRate: number
  expired: string
  expiredDay: number
}

export async function getPromotionInfo(code: string): Promise<PromotionInfo> {
  try {
    const data: PromotionInfo = await API.get('/promotion/code', {
      params: {
        promotionCode: code,
      },
    })
    console.log(data)
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export type AutoChargeItem = {
  id: number
  createdAt: string
  updatedAt: string
  deletedAt: string
  chargeAmount: string
  bonusRate: string
  type: string
  best: boolean
  bonus: number
}

export type AutoChargeList = {
  total: AutoChargeItem[]
  normal: AutoChargeItem[]
  popular: AutoChargeItem[]
}
export async function getAutoChargeList(): Promise<AutoChargeList> {
  try {
    const data: AutoChargeList = await API.get('/auto-charge/amounts', {})

    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Button } from '../atom/Button'
import { PopContentType } from '../../service/list'
import { setCookie } from '../../utils/common'
import { useCookies } from 'react-cookie'
interface HomePopupProps {
  isPopupModal: boolean
  setPopupModal: Dispatch<SetStateAction<boolean>>
  popupContent: PopContentType | undefined
}

export const HomePopup = ({
  children,
  isPopupModal,
  setPopupModal,
  popupContent,
}: PropsWithChildren<HomePopupProps>) => {
  const [cookies, setCookies] = useCookies(['fast_cut_popup'])

  const handlePopup = () => {
    if (check) {
      setCookie('fast_cut_popup', 'true', 1)
    }
    setPopupModal(false)
  }
  const [check, setCheck] = useState(false)
  const [content, setContent] = useState(popupContent)
  useEffect(() => {
    // 쿠키가 없으면 팝업을 보여줌
    if (cookies.fast_cut_popup) {
      setPopupModal(false)
    }
  }, [cookies])

  useEffect(() => {
    if (popupContent) {
      setContent(popupContent)
    }
  }, [popupContent])

  if (!isPopupModal) return <></>
  console.log(content?.description)
  console.log(content?.description.split('\\n'))
  return (
    <>
      {isPopupModal && (
        <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center  text-center p-[40px] z-50">
          <div className="bg-white w-[375px] md:w-[482px] px-5 py-8  border rounded-[20px] overflow-y-auto flex flex-col gap-6 shadow-main">
            <div>
              <p className="text-main text-[24px] font-bold">
                {content?.title}
              </p>
            </div>

            <div>
              <p className="font-bold text-[18px]">
                {content?.description.split('\\n').map((line, index) => (
                  <>
                    {`${line}`}
                    <br />
                  </>
                ))}
              </p>
            </div>

            <div className="bg-[#F6F8FB] py-3 flex flex-col">
              <p className="text-[18px] leading-[24px]">
                패스트컷 AI
                <br /> 궁금한 점은 언제든 카카오톡 채널에서 문의해주세요!
                <br />
                <a
                  className="text-href"
                  href="http://pf.kakao.com/_CFxfHxj/chat"
                  target="_blank"
                  rel="noreferrer"
                >
                  패스트컷 카카오톡 채널 {`>`}
                </a>{' '}
              </p>
            </div>
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  className="w-5 h-5 rounded-[4px]"
                  onChange={() => {
                    setCheck((prev) => !prev)
                  }}
                  checked={check}
                />
                <span className="text-[#5E6068]">오늘 하루동안 보지 않기</span>
              </div>
              <Button
                className="bg-main text-white py-3 px-8 rounded-[10px] text-[14px] font-bold"
                onClick={handlePopup}
              >
                닫기
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

import { useEffect, useState } from 'react'
import { Modal } from './Modal'
import { FreeButton } from '../atom/FreeButton'
import { FreePlanModal } from '../FreePlanModal'
import { useLocation, useNavigate } from 'react-router-dom'
import { UserType } from '../../utils/common'
import { usePostExperiencePlan } from '../../hooks/query/usePostExperiencePlan'
import useUserStore from '../../store/useUserStore'
import classNames from 'classnames'

export const Footer = () => {
  // fixed bottom-0 left-0 right-0
  const { pathname } = useLocation()
  const userInfo = useUserStore((state: any) => state.userInfo)
  const { mutate: experiencePlanMutate } = usePostExperiencePlan()
  const [signOutModal, setSignOutModal] = useState<boolean>(false)
  const [isFreePlanModal, SetFreePlanModal] = useState<boolean>(false)
  const navigate = useNavigate()
  const paymentFreePlan = () => {
    if (!userInfo?.userInfo) {
      navigate('/sign-up')
      return
    } else if (userInfo && userInfo?.userType === UserType.체험) {
      navigate('/video-editor')
      return
    }

    experiencePlanMutate('', {
      onSuccess: (data) => {
        navigate('/video-editor')
      },
      onError: () => {},
    })
  }
  useEffect(() => {
    if (signOutModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [signOutModal])
  return (
    <>
      {' '}
      <div
        className={classNames('  bg-[#141415]', {
          'mt-[20px]': pathname !== '/',
        })}
      >
        <footer className="flex flex-col items-between justify-center max-w-[1232px] px-4 py-[20px] mx-auto gap-[8px]">
          <h6 className="text-[#FFFFFF] font-bold text-[16px]">
            ANGEL LEAGUE Inc.
          </h6>
          <div className="flex flex-col gap-[8px]">
            <div className="flex flex-col gap-[2px]">
              <p className="text-[#8F929B] text-[12px]">주식회사 엔젤리그</p>
              <p className="text-[#8F929B] text-[12px]">대표이사 : 오현석</p>

              <p className="text-[#8F929B] text-[12px]">
                사업자등록번호 : 705-87-01352 통신판매업신고번호 : 제
                2021-서울강남-03485호
              </p>
              <p className="text-[#8F929B] text-[12px]">
                사업장주소 : 서울특별시 강남구 선릉로 158길 7, 4층 (청담동)
              </p>
            </div>
            <p className="text-[#8F929B] text-[12px]">
              <a
                href="https://solution.fastseller.shop/termofuse"
                target="_blank"
                rel="noreferrer"
              >
                서비스약관
              </a>{' '}
              <a
                href="https://www.solution.fastseller.shop/privacypolicy"
                target="_blank"
                rel="noreferrer"
              >
                개인정보처리방침
              </a>
              {' | '}
              <button
                onClick={() => {
                  setSignOutModal(true)
                }}
              >
                회원 탈퇴
              </button>
            </p>
            <p className="text-[#8F929B] text-[12px] flex gap-2">
              <a
                className="text-white"
                href="https://solution.fastseller.shop/"
                target="_blank"
                rel="noreferrer"
              >
                패스트셀러
              </a>
              <span>|</span>
              <a
                className="text-white"
                href="https://solution.fastseller.shop/fastshorts"
                target="_blank"
                rel="noreferrer"
              >
                패스트쇼츠
              </a>
            </p>
            <p className="text-[#5E6068] text-[14px]">
              Copyright © AngelLeague All rights reserved.
            </p>
          </div>
        </footer>
        {signOutModal && (
          <Modal isModal={signOutModal}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px] z-50">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px] text-error">회원탈퇴</p>
                <div>
                  <p className="">
                    support@capbox.kr로 회원 탈퇴 요청 부탁드립니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[400px]"
                onClick={() => {
                  setSignOutModal(false)
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}{' '}
        {isFreePlanModal && (
          <FreePlanModal
            isFreePlanModal={isFreePlanModal}
            setFreePlanModal={SetFreePlanModal}
          />
        )}
      </div>
    </>
  )
}

import { useQuery } from '@tanstack/react-query'

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import { UserInfoType } from '../service/user'
import { TemplateItem } from '../service/list'
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { CircularCheckbox } from './CircularCheckbox'

interface ModalProps {
  setVideoTypeModal: Dispatch<SetStateAction<boolean>>
  isVideoTypeModal: boolean
  userInfo: UserInfoType
  watch: UseFormWatch<{
    link: string
    mainTitle: string
    animationId: number
    voiceId: number
    bgmId: number
    backgroundId: number
    templateId: number
    templateType: string
    aiToneTypeId: number
    aiPromptDurationId: number
    subtitleId: number
  }>
  control: Control<
    {
      link: string
      mainTitle: string
      animationId: number
      voiceId: number
      bgmId: number
      backgroundId: number
      templateId: number
      templateType: string
      aiToneTypeId: number
      aiPromptDurationId: number
      subtitleId: number
    },
    any
  >
  setValue: UseFormSetValue<{
    link: string
    mainTitle: string
    animationId: number
    voiceId: number
    bgmId: number
    backgroundId: number
    templateId: number
    templateType: string
    aiToneTypeId: number
    aiPromptDurationId: number
    subtitleId: number
  }>
  videoList:
    | {
        list: TemplateItem[]
        cash: string
        originCash: number
      }
    | undefined
  etcList:
    | {
        list: TemplateItem[]
        cash: string
        originCash: number
      }
    | undefined
}

export const VideoTypeModal = ({
  children,
  setVideoTypeModal,
  isVideoTypeModal,
  userInfo,
  videoList,
  etcList,
  setValue,
  watch,
  control,
}: PropsWithChildren<ModalProps>) => {
  if (!videoList) <></>
  const [templateId, setTemplateId] = useState(watch('templateId'))

  const handleSaveBtn = () => {
    const clipInfo = videoList?.list?.filter((el) => el.id === templateId)
    const etcInfo = etcList?.list?.filter((el) => el.id === templateId)

    if (
      (templateId !== 0 && clipInfo?.length) ||
      (templateId !== 0 && etcInfo?.length)
    ) {
      setValue('backgroundId', 0)
      setValue('templateId', templateId)
      setValue('templateType', 'clip')
      setVideoTypeModal((prev) => !prev)
    }
  }
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      <div className="bg-white w-[375px] md:w-[816px]  md:h-auto  h-[100vh] px-[20px] md:px-0 border md:rounded-[40px]  overflow-y-auto pb-[150px]">
        <div className="flex flex-col">
          <div className="sticky top-0 bg-white z-10  md:px-10 pt-10  ">
            <p className="flex justify-between">
              <p className="font-bold text-[24px]">영상 타입</p>
              <button
                onClick={() => {
                  setVideoTypeModal(false)
                }}
              >
                <img
                  src="assets/img/Icon_x.png"
                  alt="icon_x"
                  height={40}
                  width={40}
                />
              </button>
            </p>
          </div>{' '}
          <div className="md:px-10 ">
            <div className="mt-4">
              <p className="text-left">
                영상으로 만들어진 템플릿을 고르시면, 지정된 위치에 이미지와
                제목이 적용됩니다.
                <br /> 이미지에 애니메이션 효과를 주지 않아도 허전하지 않아요!
              </p>

              <p className="text-[14px] text-error text-left mt-4">
                할인 이벤트중
              </p>
              <p className="flex items-center gap-1 mt-[16px]">
                {' '}
                <img
                  src="assets/img/cash.png"
                  alt="cash"
                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                />
                <s className="text-[#5E6068]  ml-1">
                  {`${videoList?.cash}`}캐시
                </s>
                <span className="text-main">{videoList?.originCash}캐시</span>
              </p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-x-[10px] gap-y-[16px] mt-10  px-5 md:p-0">
              {videoList?.list?.map((template, index) => {
                return (
                  <>
                    <div
                      className="w-full flex justify-between md:items-center"
                      key={index}
                    >
                      <div className="flex flex-col h-full gap-2">
                        <img
                          src={template.path}
                          alt="bg"
                          loading={'lazy'}
                          className="h-[240px] w-[136px]"
                        />
                        <div className="flex">
                          <Controller
                            render={({ field }) => (
                              <CircularCheckbox
                                {...field}
                                id={index}
                                checked={templateId === template.id}
                                disabled={!userInfo}
                                onChange={() => setTemplateId(template.id)}
                                label={template.name}
                              />
                            )}
                            name="templateId"
                            control={control}
                          />
                          {new Array(template.cashRate).fill(0).map((el) => (
                            <>
                              {' '}
                              <img
                                src="assets/img/cash.png"
                                alt="cash"
                                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                              />
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
            <div className="mt-10">
              <p className="flex justify-between">
                <p className="font-bold text-[24px]">기타</p>
              </p>
            </div>{' '}
            <div className="mt-4">
              <p className="text-left">
                컨텐츠 이미지에 블러 및 색상 효과를 주어 배경으로 이미지 뒤에
                깔아줍니다.{' '}
              </p>

              <p className="flex items-center gap-1 mt-[16px]">
                {' '}
                <img
                  src="assets/img/cash.png"
                  alt="cash"
                  className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                />
                <s className="text-[#5E6068]  ml-1">{`${etcList?.cash}`}캐시</s>
                <span className="text-main">{etcList?.originCash}캐시</span>
              </p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-x-[10px] gap-y-[16px] mt-10  px-5 md:p-0">
              {etcList?.list?.map((template, index) => {
                return (
                  <>
                    <div
                      className="w-full flex justify-between md:items-center"
                      key={index}
                    >
                      <div className="flex flex-col h-full gap-2">
                        <img
                          src={template.path}
                          alt="bg"
                          loading={'lazy'}
                          className="h-[240px] w-[136px]"
                        />
                        <div className="flex">
                          <Controller
                            render={({ field }) => (
                              <CircularCheckbox
                                {...field}
                                id={index}
                                checked={templateId === template.id}
                                disabled={!userInfo}
                                onChange={() => setTemplateId(template.id)}
                                label={template.name}
                              />
                            )}
                            name="templateId"
                            control={control}
                          />
                          {new Array(template.cashRate).fill(0).map((el) => (
                            <>
                              {' '}
                              <img
                                src="assets/img/cash.png"
                                alt="cash"
                                className="w-[11px] h-[13px] ml-[2px] mt-[2px]"
                              />
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className="mt-10 md:px-10">
            <button
              className="w-full text-white py-[19px] text-[18px] font-bold bg-main rounded-[40px]"
              onClick={handleSaveBtn}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

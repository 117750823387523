import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
interface Props {
  contentTooltipStatus: boolean
  setContentTooltipStatus: Dispatch<SetStateAction<boolean>>
}

const ContentTooltip = ({
  children,
  contentTooltipStatus,
  setContentTooltipStatus,
}: PropsWithChildren<Props>) => {
  return (
    <>
      {' '}
      <img
        src="assets/img/icon_tooltip.png"
        alt="툴팁"
        className="absolute right-5 top-[6px]  w-[24px] h-[24px]"
        onMouseEnter={() => {
          setContentTooltipStatus(true)
        }}
      />
      {contentTooltipStatus && (
        <div className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[200px] md:w-[360px] h-auto absolute border-[3px] border-main  rounded-[20px] bottom-[50px] right-0">
          <p className="font-bold md:text-[24px] text-[16px] flex justify-between items-center">
            컨텐츠 링크란?{' '}
            <button
              onClick={() => {
                setContentTooltipStatus(false)
              }}
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                className="md:w-[40px] md:h-[40px] w-[25px] h-[25px]"
              />
            </button>
          </p>
          <p className=" md:leading-[19.2px] md:text-base  leading-[12px] text-[10px]">
            컨텐츠가 있는 웹페이지 링크를 말해요.  네이버 블로그나 네이버 뉴스
            링크를 넣어주시면 해당 페이지에 있는 정보를 기반으로 숏폼을
            만들어드립니다.
            <br /> 저작권에 유의해주세요!
          </p>
        </div>
      )}
    </>
  )
}

export default ContentTooltip

import React, { useEffect, useState } from 'react'
import useUserStore from '../store/useUserStore'
import { Navigate, useNavigate } from 'react-router-dom'
import useTerms from '../hooks/query/useTerms'
import useGetMe from '../hooks/query/useGetMe'

export const Terms = () => {
  const userInfo = useUserStore((state: any) => state.userInfo)
  const set = useUserStore((state: any) => state.set)
  const { data: userData, refetch } = useGetMe()

  set({ userInfo: userData })

  const [isCheck, setCheck] = useState<boolean>(false)
  const navigate = useNavigate()
  const { mutate } = useTerms()
  const onAcceptedTerms = () => {
    mutate(true, {
      onSuccess: () => {
        refetch()
        navigate('/')
      },
      onError: () => {},
    })
  }

  useEffect(() => {
    if (!userInfo) {
      navigate('/')
    } else if (userInfo?.userInfo.termsAccepted) {
      navigate('/')
    }
  }, [userInfo])

  return (
    <>
      <div className="pt-[60px] max-w-[816px] h-[100vh] my-0 mx-auto flex flex-col items-center gap-[20px]">
        <p className="text-[18px] md:text-[32px]">
          🎉 {userInfo?.userInfo?.name}님 환영합니다!
        </p>
        <div className="w-[360px] md:w-[455px] flex flex-col items-center mt-[20px] gap-[20px]">
          <div className="flex justify-center items-center gap-3 text-[12px] md:text-base">
            <input
              type="checkbox"
              className="w-[20px] h-[20px]"
              onClick={() => {
                setCheck(!isCheck)
              }}
              checked={isCheck}
            />
            <p>
              패스트컷
              <a
                href="https://solution.fastseller.shop/termofuse"
                target="_blank"
                className="ml-[4px] text-[#4360EC] font-medium"
                rel="noreferrer"
              >
                이용약관,
              </a>
              <a
                href="https://solution.fastseller.shop/privacypolicy"
                target="_blank"
                className="m-[4px] text-[#4360EC] font-medium"
                rel="noreferrer"
              >
                개인정보 처리방침
              </a>
              동의
            </p>
          </div>
          <div className="w-[335px] h-[64px] flex justify-center">
            <button
              className={`w-full text-[18px] hover:bg-hover border-none border-[#D9DEE8] rounded-[40px] bg-main ${
                !isCheck && 'opacity-[20%]'
              } text-white font-bold`}
              disabled={!isCheck}
              onClick={onAcceptedTerms}
            >
              가입하기
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

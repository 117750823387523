import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Preview } from './Preview'

export const FAQ = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [isPreview, setPreview] = useState<boolean>(true)
  return (
    <div className="flex flex-col w-[343px] md:w-[816px] gap-[16px]  mb-[80px] md:mb-[120px] items-center md:items-start">
      {pathname === '/' && (
        <div className="flex flex-col gap-[16px] w-full">
          <p className="md:text-[40px] text-[34px] font-bold">
            <span className="text-main">자주 묻는 질문</span> FAQ
          </p>
        </div>
      )}
      <div className="flex flex-col p-[32px] w-full bg-[#F6F8FB] text-[#2E343D] gap-[32px] rounded-[40px]">
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            패스트컷 AI는 어떤 서비스인가요?
          </p>

          <p className="text-[#5E6068] text-[18px] ">
            패스트컷AI는 URL링크만 입력하면 AI로 웹페이지를 분석하여 숏폼 혹은
            롱폼 영상을 만들어 주는 서비스입니다.{' '}
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            패스트컷 AI로 숏폼 어떻게 만드나요?
          </p>
          <div className="text-[18px] text-[#5E6068] flex flex-col ml-2">
            <ul className="list-decimal ml-4">
              <li>
                {' '}
                텍스트와 이미지 컨텐츠가 있는 페이지를 준비해주세요! 예를 들어
                본인이 운영하고 있는 네이버 블로그 페이지나 네이버 뉴스 페이지
                등이 있습니다.
              </li>
              <li>
                {' '}
                해당 페이지의 링크를 복사해서 패스트컷 AI의{` `}
                <button
                  className="font-bold text-href underline"
                  onClick={() => {
                    navigate('/short-video-editor')
                  }}
                >
                  숏폼 만들기 페이지
                </button>
                의 안내에 따라 붙여넣으세요!
              </li>
              <li>
                {' '}
                유튜브 제목과 영상에 들어갈 효과들을 입력한 후 생성 요청하시면
                됩니다.
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            숏폼 만들 때 이미지 추가해도 되나요?
          </p>
          <p className="text-[#5E6068] text-[18px] ">
            네 가능합니다! 하나의 컨텐츠당 3개까지 추가 가능하며, 저작권에
            유의하세요!
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            자막은 오타 없나요? 수정 가능한가요?
          </p>
          <p className="text-[#5E6068] text-[18px] ">
            패스트컷 AI의 자막은 자막을 일일이 수정하는 노고를 덜어드리는 것을
            목표로 개발한 기능으로, 타 서비스들에 비해 오타 및 오류가 현저히
            적습니다. 그래서 수정 기능은 따로 준비하지 않았습니다.
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            패스트컷 AI 롱폼 기능을 사용하려면 뭘 준비해야 하나요?
          </p>
          <p className="text-[#5E6068] text-[18px] ">
            먼저 본인의 쿠팡 파트너스나 알리 익스프레스 어필리에이트 계정이
            있어야 합니다. 그리고 영상을 등록할 본인의 유튜브 채널도 준비하셔야
            하구요. 상품 정보가 잘 등록될 수 있도록 유튜브 채널의 설정이
            적합하게 되어있어야 합니다. 아래 항목들에서 꼼꼼히 확인해주세요!
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            쿠팡 파트너스 어떻게 만드나요?
          </p>
          <p className="text-[18px]">
            패스트컷이 준비한 가장 쉬운 가입 방법 안내서를 참고하세요!{' '}
            <span className="text-href underline font-bold">
              <a
                href="https://solution.fastseller.shop/15cc8c5d-bcd4-4d50-b145-4f32bad40634"
                target="_blank"
                rel="noreferrer"
              >
                쿠팡 파트너스 가입 가이드
              </a>
            </span>
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            알리익스프레스 어필리에이트 어떻게 만드나요?
          </p>
          <p className="text-[18px]">
            패스트컷이 준비한 가장 쉬운 가입 방법 안내서를 참고하세요!{' '}
            <span className="text-href underline font-bold">
              <a
                href="https://solution.fastseller.shop/2ad66cae-7290-42eb-a3f4-83fcf3fc15c8"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                알리 어필리에이트 가입 가이드{' '}
              </a>
            </span>
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            패스트컷 AI 유튜브 자동 업로드 기능이 무엇인가요?
          </p>
          <p className="text-[18px]">
            생성된 영상을 사용자의 유튜브 채널에 자동으로 업로드(영상 등록)
            해드리는 혁신적인 기능입니다. 최초 1회 유튜브 채널 관리자 권한과
            고급기능 세팅만 해 놓으면, 사용자가 따로 신경쓰지 않아도 시간
            분배까지 해서 차곡차곡 영상이 예약 등록 됩니다. 패스트컷의
            프로/프리미엄 플랜에서 제공하는 기능입니다.
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            자동 업로드 되게 하려면 어떻게 하나요?
          </p>
          <p className="text-[18px]">
            첫째, 내가 운영하는 유튜브 채널의 편집자 권한을 패스트컷 AI에게
            부여해야 합니다.{' '}
            <span className="text-href underline font-bold">
              <a
                href="https://solution.fastseller.shop/42517f3c-b0f0-416b-b57f-4bf8dd57fbfc"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                채널 권한부여 가이드{' '}
              </a>
            </span>
          </p>
          <p className="text-[18px]">
            둘째, 유튜브 고급기능 신청을 해서 승인을 받아야 합니다.{' '}
            <span className="text-href underline font-bold">
              <a
                href="https://solution.fastseller.shop/10b539b3-1e92-44e5-b136-37383f27d2e8"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                유튜브 고급기능 신청 가이드{' '}
              </a>
            </span>
          </p>
        </div>

        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            유튜브 채널 권한 부여가 무엇인가요? 어떻게 하나요?
          </p>
          <p className="text-[18px]">
            내가 운영하는 유튜브 채널에 패스트컷 AI가 영상을 등록할 수 있도록
            편집자 자격을 주는 행위입니다. 나의 유튜브 스튜디오에서 아주 간단히
            10초만에 할 수 있는 설정이에요! 가이드를 보고 미리 설정 해두시면
            좋습니다.{' '}
            <span className="text-href underline font-bold">
              <a
                href="https://solution.fastseller.shop/42517f3c-b0f0-416b-b57f-4bf8dd57fbfc"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                채널 권한부여 가이드
              </a>
            </span>
          </p>
        </div>

        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            유튜브 고급기능 신청은 왜 필요한가요? 어떻게 하나요?
          </p>
          <p className="text-[18px]">
            유튜브에 영상을 등록할 때 꼭 필요한 기능들이 있습니다. 대표 썸네일을
            고르고, 설명 내용에 상품 링크를 넣고, 댓글을 고정하는 것 등등
            인데요, 고급기능 승인을 받아야 이 기능들을 사용할 수 있어요.
            패스트컷 AI 사용을 위한 것이 아니더라도 내 유튜브 채널을 운영하는 데
            필요한 승인이니 미리 받아두시면 좋습니다. 고급기능 승인을 위해서는
            신분증 인증이 필요하니 준비 해주시고 패스트컷이 만든 가이드를 보고
            시작하세요! <br />
            <span className="text-href underline font-bold">
              <a
                href="https://solution.fastseller.shop/10b539b3-1e92-44e5-b136-37383f27d2e8"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                유튜브 고급기능 신청 가이드
              </a>
            </span>
          </p>
        </div>

        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            내가 만든 영상의 자동 업로드(영상 등록)는 언제 되나요?
          </p>
          <p className="text-[18px]">
            <ul className=" list-disc ml-5">
              <li>자동 업로드 설정이 완료된 상태여야 가능합니다. </li>
              <li>
                영상 생성 완료 후 48시간 이내에 업로드 예약이 시작됩니다.{' '}
              </li>
              <li>
                프로 플랜은 1일 최대 3개까지 오전 10시, 오후 2시, 오후 6시에
                업로드 예약 됩니다.{' '}
              </li>
              <li>
                프리미엄 플랜은 1일 최대 5개까지, 오전 9시, 오전 11시, 오후 2시,
                오후 4시, 오후 6시에 업로드 예약 됩니다.{' '}
              </li>
              <li>
                생성과 업로드 일정 등은 서비스 현황에 따라 별도의 공지 없이
                변동될 수 있습니다.{' '}
              </li>
            </ul>
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            영상 파일 다운로드, 생성과 등록 현황 등은 어디서 확인하나요?
          </p>
          <p className="text-[18px]">
            패스트컷 AI 사이트에 로그인 한 상태에서, 오른쪽 상단에 있는 [MY
            메뉴]를 선택하시면 그 하위에{' '}
            <span
              className="text-href cursor-pointer"
              onClick={() => {
                navigate('/my-videos')
              }}
            >
              [사용현황]
            </span>{' '}
            메뉴가 있어요. 사용현황 페이지에서 내가 생성 요청한 영상들의 현재
            상태와 파일 다운로드 등을 하실 수 있습니다.
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            상품명과 제목은 왜 이모지, 특수문자를 제한하고 25자 이내로 써야
            하나요?
          </p>
          <p className="text-[18px]">
            상품명과 제목은 영상의 대표 썸네일, 인트로 화면, 상단 정보 영역,
            설명 내용에 사용됩니다. 그래서 길이가 너무 길거나 특수기호 등이
            들어가면 유튜브 스튜디오 자체에서 제한하는 경우가 있고, 영상
            생성시에 폰트 오류를 일으킬 수 있어 제한하고 있습니다.
          </p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            패스트컷이 제공하는 배경음악은 저작권 문제가 없나요?
          </p>
          <p className="text-[18px]">네. 문제 없습니다!</p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            영상 썸네일은 변경해도 되나요?
          </p>
          <p className="text-[18px]">네. 변경 하셔도 됩니다!</p>
        </div>
        <div className="flex flex-col gap-[8px]">
          <p className="font-bold text-[24px]">
            쿠팡 파트너스의 고지 문구는 자동으로 들어가나요?
          </p>
          <p className="text-[18px]">네. 설명 내용에 자동으로 들어갑니다!</p>
        </div>
      </div>
    </div>
  )
}
